<template>
  <div class="wrapper">
    <div class="main-panel" v-if="!layout">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
    <dashboard-content
      @click.native="toggleSidebar"
      v-if="layout"
      :layout="layout"
    >
    </dashboard-content>
  </div>
</template>
<style lang="scss"></style>
<script>
import store from "@/store/index";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  data() {
    return {
      layout: this.$route.meta.layout,
      role_name: "",
    }
  },
  mounted() {
    this.role_name = store.getters.getUser.role_name;
  },
};
</script>
<style scoped>
.main-panel > .content {
  padding: 76px 20px 30px 20px !important;
}
</style>
