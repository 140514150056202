<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <header>
        <nav class="navbar">
            <div class="logo">
                <img src="img/logotipo2.png" alt="Logo">
            </div>
            <div class="navbar-menu">
                <a :class="{ active: isActive('rules') }" @click="goTo('dashboard')" href="#">Regras</a>
                <!-- <a :class="{ active: isActive('relatorios') }" href="#">Relatórios</a> -->
                <a :class="{ active: isActive('usuarios') }" @click="goTo('usuarios')" href="#">Usuários</a>
                <!-- <a :class="{ active: isActive('configuracoes') }" href="#">Configurações</a> -->
                <!-- <a :class="{ active: isActive('perfil') }" href="#">Meu perfil</a> -->
                <button class="logout-btn">Sair</button>
            </div>
        </nav>
        <div class="underline"></div>
    </header>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import store from "@/store";

export default {
  components: {
    CollapseTransition,
    Modal,
  },
  computed: {
    routeName() {
      const name = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    goTo(route) {
      if (route === this.$route.name) {
        return;
      }

      if (route === 'dashboard') {
        route = { name: 'dashboard' };
      }

      this.$router.push(route);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    lowerFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      store.dispatch("logout");
      this.$router.push("/join");
    },
    isActive(routeName) {
      if (this.$route.name === 'dashboard' && routeName === 'rules') {
        return true;
      }

      let routeOne = this.lowerFirstLetter(routeName);
      let routeTwo = this.lowerFirstLetter(this.$route.name);
      return routeTwo && routeTwo.includes(routeOne);
    }
  },
};
</script>
<style>

nav a {
  color: black !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white !important;
}

.logo img {
    height: 40px;
}

.navbar-menu {
    display: flex;
    gap: 20px;
}

.navbar-menu a {
    text-decoration: none;
    color: #333;
    font-weight: light;
    padding: 8px 16px;
    transition: background-color 0.3s;
}

.navbar-menu a.active {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.navbar-menu a:hover {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.logout-btn {
    background-color: #ddd;
    border: none;
    padding: 10px 70px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: light;
}

.logout-btn:hover {
    background-color: #bbb;
}

.underline {
    width: 100%;
    height: 4px;
    background-color: #4184D6;
    margin-top: 10px;
}

header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

nav a {
  color: black !important;
}

.navbar-menu a.active {
  background-color: #f1f1f1;
  border-radius: 10px;
}

</style>
