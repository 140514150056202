import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

const AuthJoin = () => import("@/pages/Auth/Join.vue");

const DronesList = () => import("@/pages/Drones/DronesList.vue");
const DronesNew = () => import("@/pages/Drones/DronesNew.vue");
const DronesEdit = () => import("@/pages/Drones/DronesEdit.vue");

const UsersList = () => import("@/pages/Users/UsersList.vue");
const UsersNew = () => import("@/pages/Users/UsersNew.vue");
const UsersEdit = () => import("@/pages/Users/UsersEdit.vue");

const PoliticaPrivacidade = () => import("@/pages/Site/PoliticaPrivacidade.vue");
const MapsTraceRoute = () => import("@/pages/Maps/MapsTraceRoute.vue");
const MapsTraceRouteSave = () => import("@/pages/Maps/MapsTraceRouteSave.vue");
const MapsTraceRouteEdit = () => import("@/pages/Maps/MapsTraceRouteEdit.vue");

const StepOne = () => import("@/pages/Rules/StepOne.vue");
const StepTwoAllDrivers = () => import("@/pages/Rules/StepTwoAllDrivers.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "politica-privacidade",
        name: "Política de Privacidade",
        component: PoliticaPrivacidade,
        meta: {
          layout: 'guest',
        },
      },
      {
        path: "join",
        name: "join",
        component: AuthJoin,
        meta: {
          layout: 'guest',
        },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "usuarios",
        name: "Usuarios",
        component: UsersList,
      },
      {
        path: "usuarios-new",
        name: "Usuarios",
        component: UsersNew,
      },
      {
        path: "usuarios-edit/:id",
        name: "Usuarios",
        component: UsersEdit,
      },
      {
        path: "planos-de-voo",
        name: "Mapa",
        component: MapsTraceRoute,
      },
      {
        path: "planos-de-voo/save",
        name: "Mapa",
        component: MapsTraceRouteSave,
      },
      {
        path: "planos-de-voo/edit/:id",
        name: "Mapa",
        component: MapsTraceRouteEdit,
      },
      {
        path: "drones",
        name: "Drones",
        component: DronesList,
      },
      {
        path: "drones-new",
        name: "Drones",
        component: DronesNew,
      },
      {
        path: "drones-edit/:id",
        name: "Drones",
        component: DronesEdit,
      },

      // Rules
      {
        path: "rules/new/step-one",
        name: "RulesStepOne",
        component: StepOne,
      },
      {
        path: "rules/new/step-two-all-drivers",
        name: "RulesStepTwoAllDrivers",
        component: StepTwoAllDrivers,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
